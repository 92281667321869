import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import SettingPasswordForAnotherUser from './SettingPasswordForAnotherUser';
import CloseIcon from '@material-ui/icons/Close';

export const AlertDialogPassword = React.memo(({userID, ...rest}) => {
  const [open, setOpen] = React.useState(false);
  const token = useSelector(state => state.gettocken.apitoken);
  const apiurl = sessionStorage.getItem('apiurl')
  const [span, setSpan] = useState('none')

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const setNewPassword = (newPassword) => {
    axios.put(`https://${apiurl}/api/User/${userID}/SetPassword`, {'password': newPassword}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    }).then(
      (res) => {
           console.log('response', res)
           setSpan('inline')
      }
    );
  };

   return (
    <>
     <Button variant="text" style={{color:'#24bced', textTransform: 'none',position: 'absolute',textDecoration: 'underline', bottom: '0px'}}  onClick={handleClickOpen} >Kennwort ändern</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...rest}
      >
        <Grid container style={{justifyContent: 'end' }}>
          <Grid item >
            <CloseIcon onClick={handleClose} style={{marginRight:' 2px', cursor: 'pointer'}} />
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spasing={3}>
            <SettingPasswordForAnotherUser span={span} setNewPassword={setNewPassword}/>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
 }
)
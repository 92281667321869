import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { checkAutoLogin } from 'src/actions/getTokenActions';

const UsersList = () => {
  const [users, setUsers] = useState([])
  
  const navigate = useNavigate()
  const isLogin = useSelector(state => state.gettocken.islogin)
  const token = useSelector(state => state.gettocken.apitoken)
  const roles = useSelector(state => state.authReducer.roles)
  const apiurl = sessionStorage.getItem('apiurl')
  const dispatch = useDispatch()

  useEffect(()=> {
    let token = checkAutoLogin(dispatch)
    if (!token && isLogin === false) {
      navigate('/login', { replace: true });
    } else {
      axios.get(`https://${apiurl}/api/User/list/internal?PageNumber=${+1}&pageSize=${20}`, {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        }}).then(
      (res) => {
        setUsers(res.data.data);
        console.log(res.data)
      }
    );
    }
  }, [isLogin])

  // if(!roles.includes('ADMIN')) {
  //   return <Card style={{marginTop: '50px', textAlign: 'center'}}>
  //     <CardContent>
  //       <Typography gutterBottom variant="h4" component="div">
  //         Sorry, you don't have access to this page
  //       </Typography>
  //       <Typography variant="body2" color="text.secondary">
  //         Only Administrator has permissions to open this page
  //       </Typography>
  //     </CardContent>
  //   </Card>
  // }

  return (
    <>
      <Helmet>
        <title>Benutzer</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Card style={{marginBottom: '10px'}}>
              <Grid container>
                <Grid item>
                  <NavLink to={{ pathname: `/app/users/newuser/`}} state={{}}>
                    <PersonAddIcon  style={{marginTop: '10px', cursor: 'pointer', marginBottom: "10px", marginLeft: '10px'}} variant="contained" fontSize="large" color="primary" autoFocus/>
                  </NavLink>
                </Grid>
                {/* <Grid item alignItems="center" >
 <Typography>ADD USER</Typography>
                </Grid> */}
              </Grid>
            </Card>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Benutzername
                        </TableCell>
                        <TableCell>
                          Vorname
                        </TableCell>
                        <TableCell>
                          Nachname
                        </TableCell>
                        <TableCell>
                          Position
                        </TableCell>
                        <TableCell>
                          Email
                        </TableCell>
                        <TableCell>
                          Rolle
                        </TableCell>
                        <TableCell>
                     Letzte IP
                        </TableCell>
                        <TableCell>
                     Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => {
                        return (
                          <TableRow
                            hover
                            key={user.userName}
                          >
                            <TableCell>
                            <NavLink style={{ color: '#24bced', textDecoration: 'underline' }} to={{ pathname: `/app/users/user/${user.id}`}} state={user}>
                              {user.userName}
                            </NavLink>
                            </TableCell>
                            <TableCell>
                              {user.firstName}
                            </TableCell>
                            <TableCell>
                              {user.lastName}
                            </TableCell>
                            <TableCell>
                              {user.description}
                            </TableCell>
                            <TableCell>
                              {user.email}
                            </TableCell>
                            <TableCell>
                              {user.roles.length === 1 ? user.roles[0] 
                              : user.roles.length === 2 ? `${user.roles[0]}, ${user.roles[1]}`
                              : `${user.roles[0]}, ${user.roles[1]}, ${user.roles[2]}`}
                            </TableCell>
                            <TableCell>
                              {user.lastIP}
                            </TableCell>
                            <TableCell>
                              {user.isActive ? 'Aktiviert' : 'Deaktiviert'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
            
          </Box>
        </Container>
      </Box>

     
    </>
  );
};
export default UsersList;

import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ListToolbar = React.memo(({
  searchItem, searchFailed, placeHolder, ...rest
 }) => {
   console.log('ListToolbar is called')
  const [inputValue, setInputValue] = useState('');

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      console.log('error', searchFailed);
      searchItem(inputValue);
    }
  };

  return (
  <Box {...rest}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      {/* <Button
        color="primary"
        variant="contained"
      >
        export
      </Button> */}
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <TextField
            id="outlined-error-helper-text"
            helperText={searchFailed ? 'Incorrect entry.' : ' '}
            error={searchFailed}
            value={inputValue}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e) => setInputValue(e.currentTarget.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder={placeHolder}
              variant="outlined"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);
});
ListToolbar.propTypes = {
  searchItem: PropTypes.any,
  searchFailed: PropTypes.bool,
  placeHolder: PropTypes.string
};

export default ListToolbar;

import CachedIcon from '@material-ui/icons/Cached';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AddTaskIcon from '@material-ui/icons/AddTask';
import DoNotTouchIcon from '@material-ui/icons/DoNotTouch';
import React from 'react';

const IsoFreigabe = React.memo(({ status }) => {
  console.log('ISOFREIGABE')
  let component;
  switch (status) {
    case 0:
      component = <CachedIcon fontSize="large" style={{ color: "#24bced" }} />;
      break;
    case 1:
      component = <AddTaskIcon fontSize="large" style={{ color: 'green' }} />;
      break;
    case 2:
      component = <DoneAllIcon fontSize="large" style={{ color: '#004444' }} />;
      break;
    case 3:
      component = <DoNotTouchIcon fontSize="large" style={{ color: "#383838" }} />;
      break;
    default:
      component = <ErrorOutlineIcon fontSize="large" style={{ color: 'red' }} />;
      break;
  }
  return (
    component
  );
});

export default IsoFreigabe;

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import PropTypes from 'prop-types';
import React from 'react';

const AccountProfile = React.memo(({firstName, lastName, jobTitle, ...rest}) => {
  return (
  
  <Card {...rest}>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src=''
          sx={{
            height: 100,
            width: 100
          }}
          style={{ color: '#f50057' }}
        >
         {getInitials(`${firstName} ${lastName}`)}
        </Avatar>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h3"
        >
          {firstName}
          {' '}
          {lastName}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {jobTitle}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {/* {`${userData.city} ${userData.country}`} */}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    {/* <CardActions>
      <Button
        color="primary"
        fullWidth
        variant="text"
      >
        Upload picture
      </Button>
    </CardActions> */}
  </Card>
)
}) 

AccountProfile.propTypes = {
  firstName: PropTypes.any,
  lastName: PropTypes.any,
  jobTitle: PropTypes.any,
};
export default AccountProfile;

import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { checkAutoLogin } from 'src/actions/getTokenActions';

const NewUser = () => {
  let user = {
    id: 0,
    accountId: 1,
    country: '',
    description: '',
    email: '',
    firstName: '',
    lastName: '',
    isActive: false,
    isAdminRole: false,
    lastIP: '',
    password: '',
    roles: [],
    rowVersion: '',
    userName: ''
  }
  const isLogin = useSelector(state => state.gettocken.islogin)
  const tokenFromState = useSelector(state => state.gettocken.apitoken)
  const apiurl = sessionStorage.getItem('apiurl')
  const navigate = useNavigate();
  const [values, setValues] = useState({ ...user })
  const [checkbox, setCheckbox] = useState(user.isActive)
  const [saved, setSaved] = useState(false)
  const [unconfirmedPassword, setUnconfirmedPassword] = useState('')
  const [err, setError] = useState(false)
  const [userNameErr, setuserNameErr] = useState(false)
  const [passwordErr, setpasswordErr] = useState(false)
  const [firstNameErr, setfirstNameErr] = useState(false)
  const [lastNameErr, setlastNameErr] = useState(false)
  const [emailErr, setemailErr] = useState(false)
  const [rolesErr, setrolesErr] = useState(false)
  //const roles = user.roles
  const [adminRole, setAdminRole] = useState(user.roles.includes("Administrator"))
  const [itRole, setITRole] = useState(user.roles.includes("IT"))
  const [serviceRole, setServiceRole] = useState(user.roles.includes("Servicedesk"))
  let newRoles = []
  let rolesForSend
  const dispatch = useDispatch()

  useEffect(() => {
    let token = checkAutoLogin(dispatch)
    if (!token && isLogin === false) {
      navigate('/login', { replace: true });
      }
}, [isLogin]);

  newRoles[0] = adminRole ? "Administrator" : ""
  newRoles[1] = itRole ? "IT" : ""
  newRoles[2] = serviceRole ? "Servicedesk" : ""
  rolesForSend = newRoles.filter(role => role !== '')

  let reqBody = {
    ...values,
    isActive: checkbox,
    roles: rolesForSend
  }

  const checkEmptyFields = () => {
    if (values.userName === '') {
      setuserNameErr(true)
    } else {
      if (values.password === '') {
        setpasswordErr(true)
      } else {
        if (values.firstName === '') {
          setfirstNameErr(true)
        } else {
          if (values.lastName === '') {
            setlastNameErr(true)
          } else {
            if (values.email === '') {
              setemailErr(true)
            } else {
            if (rolesForSend.length < 1){
              setrolesErr(true)
            } else createNewUser()
            } 
          } 
        }
      }
    }
  }
  const removeError = (fieldName) => {
    if (fieldName === 'userName') {
      setuserNameErr(false)
    } else {
      if (fieldName === 'password') {
        setpasswordErr(false)
      } else {
        if (fieldName === 'firstName') {
          setfirstNameErr(false)
        } else {
          if (fieldName === 'lastName') {
            setlastNameErr(false)
          } else {
            if (fieldName === 'email') {
              setemailErr(false)
            }
          } 
        }
      }
    }
  }

  const createNewUser = () => {
     axios.post(`https://${apiurl}/api/User/`, reqBody, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenFromState,
        }
      }).then(
        (res) => {
          console.log('response from server', res);
           if(res.status === 201 || res.status === 202 || res.status === 200) {
            setSaved(true)
          }
        })  
  }

  let stateLabel
  user.isActive ? stateLabel = 'Aktiviert' : stateLabel = ''
  const [label, setLabel] = useState(stateLabel)

  const handleChange = (event) => {
    removeError(event.target.name)
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }


  const handleChangeCheckbox = (event) => {
    setrolesErr(false)
    setCheckbox(event.target.checked)
    event.target.checked ? setLabel('Aktiviert') : setLabel('')
  }
  const handleChangeAdmin = (event) => {
    setrolesErr(false)
    setAdminRole(event.target.checked)
  }
  const handleChangeIT = (event) => {
    setrolesErr(false)
    setITRole(event.target.checked)
  }
  const handleChangeServiceDesk = (event) => {
    setrolesErr(false)
    setServiceRole(event.target.checked)
  }

  const checkPassword = () => {
    if(values.password !== unconfirmedPassword) {
      setError(true)
    } else setError(false)
  }
  return (
    <>
      <Helmet>
        <title>Benutzerprofil</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                  <form
                    autoComplete="off"
                    noValidate
                  >
                    <Card>
                      <CardHeader
                        //subheader="The information can be edited"
                        title={`Neuen Benutzer anlegen`}
                      />
                      <Divider />
                      <CardContent>
                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              error={userNameErr}
                              label="Benutzername"
                              name="userName"
                              onChange={handleChange}
                              required={true}
                              value={values.userName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              error={passwordErr}
                              label="Kennwort"
                              name="unconfirmedPassword"
                              type="password"
                              onChange={(event) => setUnconfirmedPassword(event.target.value)}
                              required
                              value={unconfirmedPassword}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Kennwort bestätigen"
                              error={err}
                              name="password"
                              type="password"
                              onChange={handleChange}
                              onBlur={checkPassword}
                              required
                              value={values.password}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                            error={firstNameErr}
                              fullWidth
                              label="Vorname"
                              name="firstName"
                              onChange={handleChange}
                              required
                              value={values.firstName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                            error={lastNameErr}
                              fullWidth
                              label="Nachname"
                              name="lastName"
                              onChange={handleChange}
                              required
                              value={values.lastName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                            error={emailErr}
                              fullWidth
                              label="Email"
                              name="email"
                              onChange={handleChange}
                              required
                              value={values.email}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Telefonnummer"
                              disabled={true}
                              name="phone"
                              //onChange={}
                              value=''
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Bezeichnung"
                              name="description"
                              onChange={handleChange}
                              value={values.description}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}><Typography style={rolesErr ? {color: 'red'} : {color: 'black'}}> Rollen: </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Checkbox style={rolesErr ? {color: 'red'} : {color: 'black'}}  name='Administrator' onChange={handleChangeAdmin} checked={adminRole} />} label="Administrator" />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Checkbox style={rolesErr ? {color: 'red'} : {color: 'black'}} name='IT' onChange={handleChangeIT} checked={itRole} />} label="IT" />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Checkbox style={rolesErr ? {color: 'red'} : {color: 'black'}} name='Servicedesk' onChange={handleChangeServiceDesk} checked={serviceRole} />} label="Servicedesk" />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}><Typography> Aktivitätsstatus : </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Switch name="isActive" checked={checkbox} onChange={handleChangeCheckbox} />} label={label} />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          p: 2
                        }}
                      >
                        {saved ? 
                        <Typography style={{color: '#24bced', marginRight: '30px',  }}>
                          Das Benutzerprofil ist gespeichert!
                        </Typography> 
                        : <Typography></Typography> }
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={checkEmptyFields}
                        >
                          Speichern
                        </Button>
                        <Button
                        style={{marginLeft: '10px', backgroundColor: '#ffd504'}}
                          variant="contained"
                        >
                           <NavLink style={{ color: '#1a3567', textDecoration: 'none' }} to={{ pathname: `/app/users/`}} >
                             Zurück
                             </NavLink>
                        </Button>
                      </Box>
                    </Card>
                  </form>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default NewUser;
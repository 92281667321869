import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import PropTypes from 'prop-types';
import React from 'react';

const TotalDownloads = React.memo(({ totalDownloads, ...rest }) => {
  console.log('TotalDownloads is called')
  let year = new Date().getFullYear()
  return  (
  <Card
    sx={{ height: '100%' }}
    {...rest}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="#ffd504"
            gutterBottom
            variant="h6"
          >
            GESAMTE DOWNLOADS {year}
          </Typography>
          <Typography
            color="white"
            variant="h3"
          >
            {totalDownloads}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: '#8D9AB3',
              color: 'black',
              height: 56,
              width: 56
            }}
          >
            <MoneyIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
})

TotalDownloads.propTypes = {
  totalDownloads: PropTypes.any
};


export default TotalDownloads;

import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import IsoFreigabe from 'src/components/iso/IsoFreigabe';
import {AlertDialog} from 'src/components/iso/AlertDialog';
import ListToolbar from 'src/components/ListToolbar';
import Sorting from 'src/components/Sorting';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CustomSkeleton } from 'src/components/CustomSkeleton';
import { checkAutoLogin } from 'src/actions/getTokenActions';
import { AlertServerReject } from 'src/components/iso/AlertServerReject';

const IsosList = () => {
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isos, setIsos] = useState([]);
  const [count, setCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [searchFailed, setClass] = useState(false);
  const token = useSelector(state => state.gettocken.apitoken);
  const isLogin = useSelector(state => state.gettocken.islogin);
  const roles = useSelector(state => state.authReducer.roles);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const myRef = useRef(null)

  // //////////////////////////////////////////////
  // Table sorting
  const [sortID, setIDSorting] = useState('none');
  const [sortDate, setDateSorting] = useState('none');
  const [sortSize, setSizeSorting] = useState('none');
  const [sortDownloads, setDownloadsSorting] = useState('none');
  const [sortTotalDuration, setTotalDurSorting] = useState('none');
  const [sortMediumDuration, setMeduimDurSorting] = useState('none');
  const [sortReleaseDate, setReleaseDateSorting] = useState('none');
  const [sortStatus, setStatusSorting] = useState('none');
  const apiurl = sessionStorage.getItem('apiurl')
  const [sort, setSort] = useState('');
  const [sortMode, setSortMode] = useState('');
  const [query, setQuery] = useState('');
  const [isScrolling, setScrolling] = useState(false)
  const [isInProgress, SetProgress] = useState(false)
  const [isAlertObj, setAlertOpen] = useState({isOpen: false, isoData: ''})


  const onUpdate = useCallback((value) => {
    setUpdate(value)
  }, [])

  const fetchData = () => {
    setScrolling(true)
    setPage(page + 1)
    console.log('scrolling', isScrolling)
  }
  const onScroll = (arr) => {
    setIsos([...isos, ...arr]);
    console.log('ISOS onscroll ', isos)
  }
  const onSortOrSearch = (arr) => {
    myRef.current.scrollIntoView()
    setIsos(arr)
    console.log('isos on sort ', isos )
  }


  const syncSorting = (func) => {
    const funcArray = [setIDSorting, setDateSorting, setSizeSorting, setDownloadsSorting, setTotalDurSorting, setMeduimDurSorting, setReleaseDateSorting, setStatusSorting];
    const sortArray = funcArray.filter((el) => el !== func);
    return sortArray.forEach((el) => el('none'));
  };
  const handleIDSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setIDSorting(sortFactor);
    syncSorting(setIDSorting);
    setPage(0)
    setSort('imageId')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);
  const handleDateSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setDateSorting(sortFactor);
    syncSorting(setDateSorting);
    setPage(0)
    setSort('uploadedOn')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);
  const handleSizeSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setSizeSorting(sortFactor);
    syncSorting(setSizeSorting);
    setPage(0)
    setSort('imageSize')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);
  const handleDownloadsSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setDownloadsSorting(sortFactor);
    syncSorting(setDownloadsSorting);
    setPage(0)
    setSort('totalDownloads')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);
  const handleTotalDurSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setTotalDurSorting(sortFactor);
    syncSorting(setTotalDurSorting);
    setPage(0)
    setSort('durationSum')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);
  const handleMediumDurSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setMeduimDurSorting(sortFactor);
    syncSorting(setMeduimDurSorting);
    setPage(0)
    setSort('durationAvg')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);
 
  const handleStatusSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setStatusSorting(sortFactor);
    syncSorting(setStatusSorting);
    setPage(0)
    setSort('status')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  useEffect(() => {
    let token = checkAutoLogin(dispatch)
    if(!token && isLogin === false) {
     navigate('/login', { replace: true });
    } else {
      axios.get(`https://${apiurl}/api/DatImage/list?PageNumber=${page+1}&pageSize=${limit}&SortMode=${sortMode}&Sort=${sort}&Query=${query}`, {headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        }}).then( res => {
          console.log('ISOS: ', res.data)
          if (isScrolling) {
            onScroll(res.data.data)
          } else {
            onSortOrSearch(res.data.data)
          }
          setCount(res.data.totalRecords);
          setScrolling(false)
        } )

    }
  }, [page, limit, sort, sortMode, update, query, isLogin])

  const searchISO = useCallback((isoName) => {
    setScrolling(false)
    setPage(0)
    setQuery(isoName)
  }, [])

  async function release(status, isoID, isoData) {
    SetProgress(true)
    if (status === 1 || status === 3) {
      try {
        const res = await axios.put(`https://${apiurl}/api/DatImage/${isoID}/activate`, { isoID }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        })
        if (update === true) {
           setUpdate(false);
          } else setUpdate(true)
      } catch (err) {
        console.log(err.endMessage)
        setAlertOpen({isOpen: true, isoData: isoData})
      }
    } else {
      try {
        const res = await axios.put(`https://${apiurl}/api/DatImage/${isoID}/deactivate`, { isoID }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        })
        if (update === true) {
          setUpdate(false);
         } else setUpdate(true)
      } catch(err) {
        console.log(err.endMessage)
        setAlertOpen({isOpen: true, isoData: isoData})
      }
    }
    SetProgress(false)
  }

  return (
    <>
    <Helmet>
      <title>ISOs</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false} >
        <ListToolbar searchItem={searchISO} searchFailed={searchFailed} placeHolder="Suche" />
        <Box sx={{ pt: 3 }}>
          <Card >
              <Dialog open={isInProgress} >
                  <CircularProgress color="info" thickness={4} size={60} style={{ position: 'fixed',marginTop: '0px', left:'50%',  width: 100, height: 100 }} />
              </Dialog>  
              <AlertServerReject isAlertObj={isAlertObj} setAlert={setAlertOpen}/>
              <Box sx={{ minWidth: 1020 }}>
              <InfiniteScroll
                      dataLength={isos.length} //This is important field to render the next data
                      next={fetchData}
                      height={'72vh'}
                      hasMore={isos.length < count}
                      //loader={<PreloaderRow />}
                      loader={<CustomSkeleton />}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b></b>
                        </p>} >
                <Table stickyHeader>
                  <TableHead ref={myRef} >
                    <TableRow>
                      <TableCell>
                        ISO ID
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortID} callback={handleIDSorting} /></span>
                      </TableCell>
                      <TableCell>
                        Datum
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortDate} callback={handleDateSorting} /></span>
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        Größe (GB)
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortSize} callback={handleSizeSorting} /></span>
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        Downloads
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortDownloads} callback={handleDownloadsSorting} /></span>
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        Dauer Gesamt
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortTotalDuration} callback={handleTotalDurSorting} /></span>
                      </TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        Durchschn. Dauer
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortMediumDuration} callback={handleMediumDurSorting} /></span>
                      </TableCell>
                      {/* <TableCell>
                        Freigebedaum
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortReleaseDate} callback={handleReleaseDateSorting} /></span>
                      </TableCell> */}
                      <TableCell style={{textAlign: 'center'}}>
                        Status
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortStatus} callback={handleStatusSorting} /></span>
                      </TableCell>
                      <TableCell>
                        {' '}
                      </TableCell>
                      <TableCell>
                        {' '}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isos.map((iso) => (
                      <TableRow
                        hover
                        key={iso.imageId}
                      >
                        <TableCell>
                          {iso.imageId}
                        </TableCell>
                        <TableCell>
                          {moment(iso.uploadedOn).locale('de').format('DD.MM.YYYY, HH:mm Z')}
                        </TableCell>
                        <TableCell style={{textAlign: 'center'}}>
                          {+(iso.imageSize/ (1024*1024*1024)).toFixed(2)}
                        </TableCell>
                        <TableCell style={{textAlign: 'center'}}>
                          {iso.totalDownloads}
                        </TableCell>
                        <TableCell style={{textAlign: 'center'}}>
                          {iso.durationSum}
                        </TableCell>
                        <TableCell style={{textAlign: 'center'}}>
                         {iso.durationAvg}
                        </TableCell>
                        <TableCell style={{textAlign: 'center'}}>
                          <IsoFreigabe status={iso.status} />
                        </TableCell>
                        <TableCell>
                          <AlertDialog
                           release={release} setUpdate={onUpdate}
                            update={update} isoData={iso.imageId} 
                            isDisabled={ !roles.includes("IT") && !roles.includes("Administrator")} status={iso.status} isoID={iso.id} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </InfiniteScroll>
              </Box>
             <div style={{
                display: 'flex', alignItems: 'center', minHeight: '52px', justifyContent: 'flex-end', padding: '5px', fontFamily: "Roboto", fontWeight: '400',
                fontSize: '0.875rem', lineHeight: 1.43, letterSpacing: '0.01071em', marginRight: '10px'
              }}>
                {'1 - '}
                {isos.length}
                {' von '}
                {count}
              </div>
          </Card>
        </Box>
      </Container>
    </Box>
  </>
  );
};

export default IsosList;

import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import { getapiEndpoint } from 'src';

const App = () => {
  const routing = useRoutes(routes);
  // const token = useSelector(state => state.gettocken.apitoken)
  // const [cookies, setCookie] = useCookies(['token'])
  // setCookie('token', token)
  return (
  
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    
  );
};

export default App;

import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import axios from 'axios';
import { AlertDialog } from '../iso/AlertDialog';
import { AlertDialogPassword } from './AlertDialogPassword';
import { checkAutoLogin } from 'src/actions/getTokenActions';

const UserData = (props) => {
  const location = useLocation()
  const user = location.state
  const isLogin = useSelector(state => state.gettocken.islogin)
  const token = useSelector(state => state.gettocken.apitoken)
  const apiurl = sessionStorage.getItem('apiurl')
  const navigate = useNavigate();
  const [values, setValues] = useState({ ...user })
  const [checkbox, setCheckbox] = useState(user.isActive)
  let stateLabel
  user.isActive ? stateLabel = 'Aktiviert' : stateLabel = ''
  const [label, setLabel] = useState(stateLabel)
  const [saved, setSaved] = useState(false)
  const roles = user.roles
  const isAdmin = user.isAdminRole
  const [adminRole, setAdminRole] = useState(user.roles.includes("Administrator"))
  const [itRole, setITRole] = useState(user.roles.includes("IT"))
  const [serviceRole, setServiceRole] = useState(user.roles.includes("Servicedesk"))
  let newRoles = []
  let rolesForSend
  const dispatch = useDispatch()

  useEffect(() => {
    let token = checkAutoLogin(dispatch)
    if (!token && isLogin === false) {
      navigate('/login', { replace: true });
      }
}, [isLogin]);

  newRoles[0] = adminRole ? "Administrator" : ""
  newRoles[1] = itRole ? "IT" : ""
  newRoles[2] = serviceRole ? "Servicedesk" : ""
  rolesForSend = newRoles.filter(role => role !== '')

  let reqBody = {
    ...values,
    isActive: checkbox,
    roles: rolesForSend
  }

  const updateUserData = () => {
    console.log('request BODY', reqBody)
    axios.put(`https://${apiurl}/api/User/${user.id}`, reqBody, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      }).then(
        (res) => {
          if(res.status === 202) {
             setSaved(true)
             console.log('response', res)
          }
        }
      );
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeCheckbox = (event) => {
    
    setCheckbox(event.target.checked)
    event.target.checked ? setLabel('Aktiviert') : setLabel('')
  }

  const handleChangeAdmin = (event) => {
    setAdminRole(event.target.checked)
  }
  const handleChangeIT = (event) => {
    setITRole(event.target.checked)
  }
  const handleChangeServiceDesk = (event) => {
    setServiceRole(event.target.checked)
  }
  return (
    <>
      <Helmet>
        <title>Benutzerprofil</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                  <form
                    autoComplete="off"
                    noValidate
                  >
                    <Card>
                      <CardHeader
                        //subheader="The information can be edited"
                        title={`Benutzerprofil von  ${user.firstName} ${user.lastName}`}
                      />
                      <Divider />
                      <CardContent>

                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Benutzername"
                              name="userName"
                              //onChange={handleChange}
                              disabled={isAdmin}
                              required
                              value={values.userName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Passwort"
                              name="password"
                              type="password"
                              //onChange={handleChange}
                              //disabled={isAdmin}
                              disabled={true}
                              required
                              value={values.password}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            style={{position: 'relative'}}
                          >
                            <AlertDialogPassword userID = {user.id}/>
                            {/*  */}
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Vorname"
                              name="firstName"
                              onChange={handleChange}
                              required
                              value={values.firstName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Nachname"
                              name="lastName"
                              onChange={handleChange}
                              required
                              value={values.lastName}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Email"
                              name="email"
                             // onChange={handleChange}
                             disabled={isAdmin}
                              required
                              value={values.email}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Telefonnummer"
                              disabled={true}
                              name="phone"
                              //onChange={}
                              value=''
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Bezeichnung"
                              name="description"
                              onChange={handleChange}
                              required
                              value={values.description}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}><Typography> Rollen: </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Checkbox name='Administrator' disabled={isAdmin} onChange={handleChangeAdmin} checked={adminRole} />} label="Administrator" />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Checkbox name='IT' onChange={handleChangeIT} checked={itRole} />} label="IT" />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Checkbox name='Servicedesk' onChange={handleChangeServiceDesk} checked={serviceRole} />} label="Servicedesk" />
                          </Grid>
                          <Grid
                            item
                            xl={3}
                            xs={12}><Typography> Aktivitätsstatus : </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={9}
                            xs={12}>
                            <FormControlLabel control={<Switch name="isActive" disabled={isAdmin} checked={checkbox} onChange={handleChangeCheckbox} />} label={label} />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          p: 2
                        }}
                      >
                        {saved ? 
                        <Typography style={{color: '#24bced', marginRight: '30px',  }}>
                          Das Benutzerprofil ist gespeichert!
                        </Typography> : <Typography></Typography> }
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={updateUserData}
                        >
                          Speichern
                        </Button>
                        <Button
                        style={{marginLeft: '10px', backgroundColor: '#ffd504'}}
                          variant="contained"
                        >
                           <NavLink style={{ color: '#1a3567', textDecoration: 'none' }} to={{ pathname: `/app/users/`}} >
                             Zurück
                             </NavLink>
                          
                        </Button>
                      </Box>
                    </Card>
                  </form>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default UserData;

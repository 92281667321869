import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Typography,
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';

const AgentCard = ({ agent, ...rest }) => (
  <Box {...rest}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
      mb={3}
      mt={3}
    >
      <Button
        color="primary"
        variant="contained"
      >
        export
      </Button>
    </Box>
    <Box mb={3}>
      <Paper elevation={3} square>
      <Card>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xl={6} xs={12}>
              <Typography pb={2} variant="h5">
                {agent.agentNummer}
              </Typography>
                <Typography>
                  {'Volume diesen Monat: '}
                 {agent.monthVolume}
                 {' GB'}
                </Typography>
              <Typography>
                {'Downloads diesen Monat: '}
                {agent.monthDownloads}
                {' GB'}
              </Typography>
            </Grid>
            <Grid item xl={6} xs={12}>
              <Typography pb={2} variant="h5">
                Status
              </Typography>
              <Typography>
                {agent.status}
              </Typography>
              <Typography>
                {' '}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
    </Box>
  </Box>
);

AgentCard.propTypes = {
  agent: PropTypes.any
};

export default AgentCard;

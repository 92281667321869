import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import { updateUserPasswordTC } from 'src/reducers/auth-reducer';
import { useDispatch, useSelector } from 'react-redux';

const SettingsPassword = React.memo((props) => {
  const [err, setError] = useState(false)
  const token = useSelector(state => state.gettocken.apitoken);
  const password = useSelector(state => state.authReducer.password)
  const [span, setSpan] = useState('none')
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
   
  }

  const updatePassword = () => {
     if(values.password !== values.confirm) {
      setError(true)
    } else {
      setError(false)
      dispatch(updateUserPasswordTC(token, values.confirm))
      setValues({password:'', confirm: ''})
      setSpan('inline')
    }
  }

  return (
    <form {...props}>
      <Card>
        <CardHeader
          subheader="Kennwort aktualisieren "
          title="Passwort"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Neues Kennwort"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            error={err}
            label="Kennwort bestätigen"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Typography style={{ marginRight: '10px', color: '#24bced' ,display: `${span}`}} >Password ist geändert!</Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={updatePassword}
          >
            Aktualisieren
          </Button>
        </Box>
      </Card>
    </form>
  );
});

export default SettingsPassword;

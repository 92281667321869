import {
  Box,
  Card,
  CardContent,
  Paper,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  Dialog,
  CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, {  useState } from 'react';
import { useSelector } from 'react-redux';
import { AlertTestCustomer } from './AlertTestCustomer';

const CustomerToolbar = React.memo(({ customer, searchItem, testuser, ...rest }) => {
  console.log('Customer: ', customer)
  console.log('Customer.testuser ', customer.testuser)

  const roles = useSelector(state => state.authReducer.roles)
  const [inputValue, setInputValue] = useState('')
  const [isInProgress, SetProgress] = useState(false)
  const [label, setLabel] = useState(customer.testuser === "1" ? "Ja" : "Nein")
  const [checkbox, setCheckbox] = useState(customer.testuser === "1" ? true : false) 
  const [isAlertObj, setAlertOpen] = useState({ isOpen: false, customerNumber: '', text: '' })
  const token = useSelector(state => state.gettocken.apitoken);
  const userId = customer.id

  async function handleChangeCheckbox(event) {
    setCheckbox(event.target.checked)
    event.target.checked ? setLabel('Ja') : setLabel('Nein')
    SetProgress(true)
    //if (customer.testuser === "0") {
    if (event.target.checked) {
      try {
        const res = await axios.put(`https://${apiurl}/api/User/${userId}/maketestuser`, { userId }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        })
        SetProgress(false)
        setAlertOpen({isOpen: true, customerNumber: customer.userName, text: 'Testbenutzer'})
      } catch (err) {
        console.log('ERROR: ', err)
      }
    } else {
      try {
        const res = await axios.put(`https://${apiurl}/api/User/${userId}/removetestuser`, { userId }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        })
        console.log('RESSSS: ', res.data)
        SetProgress(false)
        setAlertOpen({isOpen: true, customerNumber: customer.userName, text:'Normalbenutzer'})
      } catch (err) {
        console.log('ERROR: ', err)
      }
    }
   
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {

      searchItem(inputValue);
    }
  };
  return (
    <Box {...rest}>
       <Dialog open={isInProgress} >
                  <CircularProgress color="info" thickness={4} size={60} style={{ position: 'fixed',marginTop: '0px', left:'50%',  width: 100, height: 100 }} />
       </Dialog>  
      <AlertTestCustomer isAlertObj={isAlertObj} setAlert={setAlertOpen} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        mb={3}
      >
      </Box>
      <Paper elevation={3} square>
        <Card>
          <CardContent>
            <Grid container spacing={3} alignItems="center">
              <Grid item xl={3} xs={12}>
                <Typography pb={2} variant="h5">
                  {'Kundenname: '}
                </Typography>
                <Typography pb={2} variant="h5">
                  {customer.firstName}
                  {' '}
                  {customer.lastName}
                </Typography>
              </Grid>
              <Grid item xl={3} xs={12}>
                <Typography pb={2} variant="h5">
                  {'Kundennummer: '}
                </Typography>
                <Typography>
                  {' #'}
                  {customer.userName}
                </Typography>
              </Grid>
              <Grid item xl={3} xs={12}>
                <Typography pb={2} variant="h5">
                  {'Kundenkontakt: '}
                </Typography>
                <Typography>
                  {customer.email}
                </Typography>
                <Typography>
                  {/* {customer.phone} */}
                </Typography>
              </Grid>
              <Grid item xl={3} xs={12} style ={{visibility: !roles.includes("IT") && !roles.includes("Administrator") ? 'hidden' : 'inherit'}}> 
                <Typography pb={2} variant="h5">
                  als Testbenutzer festlegen
                </Typography>
                <Typography>
                  <FormControlLabel control={<Switch  checked={checkbox} onChange={handleChangeCheckbox} />} label={label} />
                </Typography>
                <Typography>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )

}) 
CustomerToolbar.propTypes = {
  customer: PropTypes.any
}

export default CustomerToolbar;

import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PropTypes from 'prop-types';
import React from 'react';

const TotalCustomers = React.memo(({ totalCustomers, ...rest }) => {
  console.log('TotalCustomers is called')
  return (
  <Card {...rest}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="#ffd504"
            gutterBottom
            variant="h6"
          >
            GESAMTE KUNDEN
          </Typography>
          <Typography
            color="white"
            variant="h3"
          >
            {totalCustomers}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: '#8D9AB3',
              color: 'black',
              height: 56,
              width: 56
            }}
          >
            <PeopleIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
})

TotalCustomers.propTypes = {
  totalCustomers: PropTypes.any
};

export default TotalCustomers;

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PropTypes from 'prop-types';
import React from 'react';

const Sorting =React.memo((props) => {
  console.log('SORTING is called')
  switch (props.sortFactor) {
    case 'none':
      return <ArrowUpwardIcon onClick={() => props.callback('AZ')} fontSize="small" color="disabled" />;
    case 'AZ':
      return <ArrowUpwardIcon onClick={() => props.callback('ZA')} fontSize="small" style={{ color: '#1a3567' }} />;
    case 'ZA':
      return <ArrowDownwardIcon onClick={() => props.callback('none')} fontSize="small" style={{ color: '#1a3567' }} />;
    default:
      return <ArrowUpwardIcon onClick={() => props.callback('AZ')} fontSize="small" color="disabled" />;
  }
});

Sorting.propTypes = {
  sortFactor: PropTypes.string,
  callback: PropTypes.any
};

export default Sorting;

import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
      custom: '#064481'
    },
    primary: {
      contrastText: '#ffd504',
      main: '#1A3567'
    },
    text: {
      primary: '#000',
      secondary: '#24bced'
    }
  },
  shadows,
  typography
});

export default theme;

import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const SettingPasswordForAnotherUser = React.memo((props) => {
  const [err, setError] = useState(false)
  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }

  const updatePassword = () => {
     if(values.password !== values.confirm) {
      setError(true)
    } else {
      setError(false)
      props.setNewPassword(values.confirm)
      setValues({password:'', confirm: ''})
    }
  }

  return (
    <form {...props}>
      <Card>
        <CardHeader
          title="Kennwort aktualisieren "
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Neues Kennwort"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            error={err}
            label="Kennwort bestätigen"
            margin="normal"
            name="confirm"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Typography style={{ marginRight: '10px', color: '#24bced' ,display: `${props.span}`}} >Password ist geändert!</Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={updatePassword}
          >
            Aktualisieren
          </Button>
        </Box>
      </Card>
    </form>
  );
});

export default SettingPasswordForAnotherUser;
import { getapiEndpoint } from "src";
import { getAuthUserDataThunk } from "src/reducers/auth-reducer";

export const GET_TOKEN_BEGIN = 'POST_LOGING_BEGIN';
export const GET_TOKEN_SUCCESS = 'POST_LOGING_SUCCESS';
export const GET_TOKEN_FAILURE = 'POST_LOGING_FAILURE';
export const GET_TOKEN_LOGIN = 'GET_TOKEN_LOGIN';
export const GET_TOKEN_LOGOUT = 'GET_TOKEN_LOGOUT';

export const getTokenBegin = () => ({
  type: GET_TOKEN_BEGIN
});

export const getTokenSuccess = (tokendata) => ({
  type: GET_TOKEN_SUCCESS,
  payload: { tokendata }
});
export const getTokenLogin = (islogin) => ({
  type: GET_TOKEN_LOGIN,
  payload: { islogin }
});
export const getTokenLogout = (islogin) => ({
  type: GET_TOKEN_LOGOUT,
  payload: { islogin }
});

export const getTokenFailure = (error) => ({
  type: GET_TOKEN_FAILURE,
  payload: { error }
});

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function getToken(params) {
  const apiurl = sessionStorage.getItem('apiurl')
  return (dispatch) => {
    dispatch(getTokenBegin());
    return fetch(`https://${apiurl}/api/Token`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const tokendata = json.token;
        dispatch(getTokenSuccess(tokendata));
        dispatch(getTokenLogin());
        saveTokenInLocalStorage(tokendata,dispatch)
        let time = tokendata.expiresIn
        // dispatch(getTokenSuccess(json));
        return json;
      })
      .catch((error) => dispatch(getTokenFailure(error)));
  };
}

export const saveTokenInLocalStorage = (tokendata, dispatch) => {
  //localStorage.setItem('token', tokendata)
  sessionStorage.setItem('token', tokendata)
  //runLogoutTimer(dispatch)
}

export const checkAutoLogin = (dispatch) => {
  const tokenDetails = sessionStorage.getItem('token')
  if(!tokenDetails) {
    dispatch(getTokenLogout(false))
    return null
  } else {
    dispatch(getTokenSuccess(tokenDetails))
    dispatch(getAuthUserDataThunk(tokenDetails))
    return tokenDetails
  }
}

// export const runLogoutTimer = (dispatch) => {

//   setTimeout(() => {

//     //dispatch(getTokenLogout(false))
//     removeTokenInLocalStorage(false, dispatch)
//   }, 3600000)
// }

export const removeTokenInLocalStorage = (bool, dispatch) => {

  localStorage.removeItem('token')
  sessionStorage.removeItem('token')
  dispatch(getTokenLogout(bool))
}

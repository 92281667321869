import { Skeleton } from '@material-ui/core';

export const CustomSkeleton = () => {
  return (
    <>
      <Skeleton animation="wave" variant="text" height={57} />
      <Skeleton animation="wave" variant="text" height={57} />
      <Skeleton animation="wave" variant="text" height={57} />
      <Skeleton animation="wave" variant="text" height={57} />
      <Skeleton animation="wave" variant="text" height={57} />
    </>
  )
}
import { Doughnut } from 'react-chartjs-2';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';

const DistributionByCountrys = ({ countries, ...rest }) => {
  const theme = useTheme();

  let downloadsArray = countries.map(el => el.downloads)
  let procentData = []
  let countriesForDiagram = []
  const diagramcolors = [{ color: '#00264d' }, { color: '#064481' }, { color: '#ffd504' }, { color: '#24bced' },
  { color: '#282c2f' }, { color: '#f50057' }, { color: '#01786F' }, { color: '#576675' }, {color: 'black'}, {color: 'red'}];

  if (downloadsArray.length > 0) {
    let amountOfDownloads = downloadsArray.reduce((total = 0, amount = 0) => total + amount)
    //procentData = countries.map(el => { return { ...el, color: '', downloads: (el.downloads * 100 / amountOfDownloads).toFixed(2) } })
    procentData = countries.map(el => { return { ...el, color: '', downloads: (el.downloads * 100 / amountOfDownloads) < 1 ? (el.downloads * 100 / amountOfDownloads).toFixed(1) : (el.downloads * 100 / amountOfDownloads).toFixed(0)}})
    

    // Create a new array with property 'color'
      diagramcolors.length = procentData.length
      countriesForDiagram = diagramcolors.map((obj, index) => {
      const res = procentData[index];
      res.color = obj.color;
      return res;
    });
  }
  countriesForDiagram = countriesForDiagram.filter(el => el.downloads !== "0.0")
  console.log('countriesForDiagram: ', countriesForDiagram)
  

  const data = {
    datasets: [
      {
        data: countriesForDiagram.map((c) => c.downloads),
        backgroundColor: countriesForDiagram.map((c) => c.color),
        borderWidth: 1,
        // borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      }
    ],
    labels: countriesForDiagram.map((c) => c.country)
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: false,
        },
        title: {
          display: true
        },
      },
    },

    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 2,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card {...rest}>
      <CardHeader title="Verteilung nach Ländern" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {countriesForDiagram.map(({
            color,
            country,
            downloads
          }) => (
            <Box
              key={country}
              sx={downloads !== '0' ? {
                p: 1,
                textAlign: 'center'
              } : {textAlign: 'center'}}
            >
              <Typography
                color={color}
                variant="h4"
              >
                {downloads !== '0' ? country : '' }
              </Typography>
              <Typography
               style={{ color }}
                variant="h4"
              >
                {downloads !== '0' ? `${downloads}%` : '' }
                
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

DistributionByCountrys.propTypes = {
  countries: PropTypes.any
};

export default React.memo(DistributionByCountrys);

import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  // colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Helmet } from 'react-helmet';
import AgentCard from 'src/components/agents/AgentCard';

const Agent = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const downloads = location.state.everyDayDownloads;

  const data = {
    datasets: [
      {
        backgroundColor: '#064481',
        data: downloads.map((download) => download.amountOfDownloads),
        label: 'September 2021'
      },
      // {
      //   backgroundColor: colors.grey[400],
      //   data: [11, 20, 12, 29, 30, 25, 13],
      //   label: 'Last year'
      // }
    ],
    labels: downloads.map((download) => download.date)
  };

  const options = {
    animation: false,
    barThickness: 17,
    cornerRadius: 5,
    layout: { padding: 0 },
    legend: { display: true },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };
  return (
    <>
      <Helmet>
        <title>
          {location.state.agentNummer}
        </title>
      </Helmet>
      <AgentCard agent={location.state} />
      <Card {...props}>
        <CardHeader
          action={(
            <Button
              endIcon={<ArrowDropDownIcon />}
              size="small"
              variant="text"
            >
              Smth else
            </Button>
          )}
          title={location.state.agentNummer}
        />
        <Divider />
        <CardContent>
          <Box
            sx={{
              height: 500,
              position: 'relative'
            }}
          >
            <Bar
              data={data}
              options={options}
            />
          </Box>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
          >
            Smth else
          </Button>
        </Box>
      </Card>
    </>

  );
};

export default Agent;

import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import TotalDownloads from 'src/components/dashboard//TotalDownloads';
// import LatestOrders from 'src/components/dashboard//LatestOrders';
// import TasksProgress from 'src/components/dashboard//TasksProgress';
import TotalCustomers from 'src/components/dashboard//TotalCustomers';
import TotalDataVolume from 'src/components/dashboard//TotalDataVolume';
import DistributionByCountrys from 'src/components/dashboard/DistributionByCountrys';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import NumberOfDownloads from 'src/components/dashboard/NumberOfDownloads';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import React from 'react';
import { checkAutoLogin } from 'src/actions/getTokenActions';
import { getapiEndpoint } from 'src';

const Dashboard = React.memo(() => {
  console.log('Dashboard is called')
  const [countries, setCountries] = useState([]);
  const [dashboardData, setDashboard] = useState({});
  const [isos, setIsos] = useState([]);
  //const token = useSelector(state => state.gettocken.apitoken);
  const isLogin = useSelector(state => state.gettocken.islogin);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const apiurl = sessionStorage.getItem('apiurl')
  
  useEffect(() => {
    let token = checkAutoLogin(dispatch)
    if (!token && isLogin === false) {
      navigate('/login', { replace: true });
    } else {
      axios.get(`https://${apiurl}/api/Chart/images`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      }).then(res => {
        setIsos(res.data);
      })
      axios.get(`https://${apiurl}/api/Chart/countries`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      }).then(res => {
        console.log('Countries: ', res.data)
        setCountries(res.data);
      })
      axios.get(`https://${apiurl}/api/Chart/totals`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      }).then(res => {
        
        setDashboard(res.data);
      })
    }

  }, [isLogin]);


  
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <TotalDownloads totalDownloads={dashboardData.totalDownloads} style={{ backgroundColor: '#1A3567' }} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <TotalCustomers totalCustomers={dashboardData.totalUser} style={{ backgroundColor: '#1A3567' }} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={12}
              xl={4}
              xs={12}
            >
              <TotalDataVolume
                totalDataVolume={+(dashboardData.totalVolume / (1024*1024*1024)).toFixed(2)}
                style={{ backgroundColor: '#1A3567' }}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
              mt={7}
            >
              <NumberOfDownloads isosDownloads={isos} />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
              mt={7}
            >
              <DistributionByCountrys countries={countries} sx={{ height: '100%' }} />
            </Grid>
            {/* <Grid
            item
            lg={12}
            md={12}
            xl={12}
            xs={12}
          >
            <LatestOrders />
          </Grid> */}
          </Grid>
        </Container>
      </Box>
    </>
  );
});

export default Dashboard;

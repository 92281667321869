import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store/index';
import axios from 'axios';
import { CookiesProvider } from 'react-cookie';

let urlElements = window.location.href.split('/')
let url = (urlElements[2])
sessionStorage.setItem('url', url)
//let url = 'localhost:5001'
let apiurl

// axios.get("https://" + url + "/ApiEndpoint").then(res => {
//   apiurl = res.data.apiEndpoint
//   console.log('apiurl ', apiurl)
//   window.apiurl = apiurl }

if (url === "localhost:3000") {
  window.apiurl = "dat-update-stage.webquake.com"
  //window.apiurl = "localhost:44342"
  apiurl = window.apiurl
  sessionStorage.setItem('apiurl', window.apiurl)
} else {
  axios.get("https://" + url + "/ApiEndpoint").then(res => {
    apiurl = res.data.apiEndpoint
    console.log('apiurl ', apiurl)
    window.apiurl = apiurl
    sessionStorage.setItem('apiurl', window.apiurl)
  })
  }

  
// export const getapiEndpoint = () => {

//   debugger
//     return  axios.get("https://" + url + "/ApiEndpoint")
//   }


// if (url === "localhost:3000" || url === "dat-update-stage.webquake.com" ){
// window.apiurl = "dat-update-stage.webquake.com"
// window.apiurl = 



ReactDOM.render((
  <CookiesProvider>
    <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  </CookiesProvider>
), document.getElementById('root'));

// serviceWorker.unregister();

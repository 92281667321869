import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import AccountProfile from 'src/components/account/AccountProfile';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';
import SettingsPassword from 'src/components/account/SettingsPassword';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { checkAutoLogin } from 'src/actions/getTokenActions';

const Account = () => {
  const navigate = useNavigate();
  const isLogin = useSelector(state => state.gettocken.islogin);
  const firstName = useSelector(state => state.authReducer.firstName );
  const lastName = useSelector(state => state.authReducer.lastName );
  const jobTitle = useSelector(state => state.authReducer.description );
  const email = useSelector(state => state.authReducer.email );
  const dispatch = useDispatch()

  useEffect(()=> {
    let token = checkAutoLogin(dispatch)
    if (!token && isLogin === false) {
      navigate('/login', { replace: true });
    }
  }, [isLogin])

  

  return (
  <>
    <Helmet>
      <title>Account</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <AccountProfile firstName={firstName} lastName={lastName} jobTitle={jobTitle} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <AccountProfileDetails firstName={firstName} lastName={lastName} jobTitle={jobTitle} email={email} />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            {' '}
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <SettingsPassword />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

} 
export default Account;

import { Helmet } from 'react-helmet';
import ListToolbar from 'src/components/ListToolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Sorting from 'src/components/Sorting';
import { CustomSkeleton } from 'src/components/CustomSkeleton';
import { checkAutoLogin } from 'src/actions/getTokenActions';


const CustomerList = () => {
  console.log('CUSTOMERList is called')
  const isLogin = useSelector(state => state.gettocken.islogin);
  const navigate = useNavigate();
  const apiurl = sessionStorage.getItem('apiurl')
  const dispatch = useDispatch()

  const myRef = useRef(null)

  const [searchFailed, setClass] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isScrolling, setScrolling] = useState(false)

  const [sortLands, setLandSorting] = useState('none');
  const [sortCustomers, setCustomerSorting] = useState('none');
  const [sortDownloads, setDownloadsSorting] = useState('none');
  const [sort, setSort] = useState('');
  const [sortMode, setSortMode] = useState('');
  const [query, setQuery] = useState('');

  const [customers, setCustomers] = useState([]);
  const [count, setCount] = useState(0);
  const onScroll = (arr) => {
    setCustomers([...customers, ...arr]);
    console.log('customers on scroll', customers)
  }
  const onSortOrSearch = (arr) => {
    myRef.current.scrollIntoView()
    setCustomers(arr)
    console.log('customers on sort', customers)
  }

  useEffect(() => {
    let token = checkAutoLogin(dispatch)
    if (!token && isLogin === false) {
      navigate('/login', { replace: true });
    } else {
      axios.get(`https://${apiurl}/api/User/list?PageNumber=${page + 1}&pageSize=${limit}&SortMode=${sortMode}&Sort=${sort}&Query=${query}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      }).then(res => {
        if (isScrolling) {
     
          onScroll(res.data.data)
        } else {
          onSortOrSearch(res.data.data)
        }
        setCount(res.data.totalRecords);
        setScrolling(false)
      })
    }
  }, [page, limit, sort, sortMode, query, isLogin]);

  const searchCustomer = useCallback((customerName) => {
    setScrolling(false)
    setPage(0)
    setQuery(customerName)
  }, []);

  const handleLandSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setLandSorting(sortFactor);
    setPage(0)
    setDownloadsSorting('none');
    setCustomerSorting('none');
    setSort('country')
    if (sortFactor === 'AZ') {
      setSortMode('asc')
    } else if (sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const handleDownloadSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setLandSorting('none');
    setDownloadsSorting(sortFactor);
    setPage(0)
    setCustomerSorting('none');
    setSort('downloadsThisMonth')
    if (sortFactor === 'AZ') {
      setSortMode('asc')
    } else if (sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const handleCustomerSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setLandSorting('none');
    setDownloadsSorting('none');
    setCustomerSorting(sortFactor);
    setPage(0)
    setSort('userName')
    if (sortFactor === 'AZ') {
      setSortMode('asc')
    } else if (sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const fetchData = () => {
    setScrolling(true)
    setPage(page + 1)
  }
  return (
    <>
      <Helmet>
        <title>Kunden</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ListToolbar placeHolder="Suche" searchFailed={searchFailed} searchItem={searchCustomer} />
          <Box sx={{ pt: 3 }}>
            <Card id='content'>
              <Box sx={{ minWidth: 1050 }} >
                <InfiniteScroll
                  dataLength={customers.length} //This is important field to render the next data
                  next={fetchData}
                  height={'72vh'}
                  hasMore={customers.length < count}
                 // loader={<PreloaderRow />}
                  loader={<CustomSkeleton />}
                  // useWindow={false}
                  // getScrollParent={ () => document.getElementById('content') }
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b></b>
                    </p>} >
                  <Table stickyHeader  >
                    <TableHead ref={myRef} >
                      <TableRow>
                        <TableCell style={{ maxWidth: '400px' }}>
                          Land
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortLands} callback={handleLandSorting} /></span>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          Kundennummer
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortCustomers} callback={handleCustomerSorting} /></span>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          Letzte IP
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          Downloads im aktuellen Monat
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortDownloads} callback={handleDownloadSorting} /></span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {customers.map((customer) => (
                       <ScrollItem key={customer.id} customer={customer} />
                      ))}
                    </TableBody> 
                  </Table>
                </InfiniteScroll>
              </Box>
              <div style={{
                display: 'flex', alignItems: 'center', minHeight: '52px', justifyContent: 'flex-end', padding: '5px', fontFamily: "Roboto", fontWeight: '400',
                fontSize: '0.875rem', lineHeight: 1.43, letterSpacing: '0.01071em', marginRight: '10px'
              }}>
                {'1 - '}
                {customers.length}
                {' von '}
                {count}
              </div>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  )
};

export default CustomerList;

const ScrollItem = (props) => {
  return (
    <TableRow
      hover
      key={props.customer.id}
    >
      <TableCell style={{ maxWidth: '400px' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Typography
            color="textPrimary"
            variant="body1"
          >
            {props.customer.country}
          </Typography>
        </Box>
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        <NavLink style={{ color: '#24bced', textDecoration: 'underline' }} to={{ pathname: `/app/test/${props.customer.id}` }} state={props.customer}>
          {props.customer.userName}
        </NavLink>
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        {props.customer.lastIP}
      </TableCell>
      <TableCell style={{ textAlign: 'center' }}>
        {props.customer.downloadsThisMonth}
      </TableCell>
    </TableRow>
  )
}

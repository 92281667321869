import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export const AlertTestCustomer = React.memo((props) => {
  return <Dialog
    open={props.isAlertObj.isOpen}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
   
  >
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Der Kunde #{props.isAlertObj.customerNumber} wurde als {props.isAlertObj.text} festgelegt.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.setAlert({isAlertObj: false, customerNumber: ''})} variant="contained" size="small" color="primary" autoFocus>
        Ok
      </Button>
    </DialogActions>
  </Dialog>
})

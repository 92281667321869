import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
// import ProductListToolbar from 'src/components/iso/ProductListToolbar';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DoneIcon from '@material-ui/icons/Done';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { checkAutoLogin } from 'src/actions/getTokenActions';


const Agents = () => {
  const [agents, setAgents] = useState([]);
  //const token = useSelector(state => state.gettocken.apitoken);
  const isLogin = useSelector(state => state.gettocken.islogin);
  const navigate = useNavigate();
  const apiurl = sessionStorage.getItem('apiurl')
  const dispatch = useDispatch()

  useEffect(() => {
    let token = checkAutoLogin(dispatch)
    if (!token && isLogin === false) {
      navigate('/login', { replace: true })
    } else {
      axios.get(`https://${apiurl}/api/DatAgent/list?PageNumber=${1}&pageSize=${10}`, {headers: {
      'Acept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      }}).then( res => {
        console.log(res.data);
        setAgents(res.data.data)
      } )
    }
  }, [isLogin]);

  return (
    <>
      <Helmet>
        <title>AGENTS</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {/* <ProductListToolbar /> */}
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Agent
                        </TableCell>
                        <TableCell>
                          Agent URI
                        </TableCell>
                        <TableCell>
                          Volumen diesen Monat
                        </TableCell>
                        <TableCell>
                          Volumen letzte 24h
                        </TableCell>
                        <TableCell>
                          Downloads diesen Monat
                        </TableCell>
                        <TableCell>
                          Downloads letzte 24h
                        </TableCell>
                        <TableCell>
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {agents.map((agent) => {
                        let icon;
                        if (agent.status === 1) {
                          icon = <DoneIcon style={{ color: 'green' }} />;
                        } else {
                          icon = <RemoveCircleOutlineIcon style={{ color: 'red' }} />;
                        }
                        return (
                          <TableRow
                            hover
                            key={agent.agentName}
                          >
                            <TableCell>
                              {/* <NavLink style={{ color: '#24bced', textDecoration: 'underline' }} to={{ pathname: `/app/agent/${agent.agentID}` }} state={agent}> */}
                                {agent.agentName}
                              {/* </NavLink> */}
                            </TableCell>
                            <TableCell>
                             {agent.agentUri}
                            </TableCell>
                            <TableCell>
                              {(agent.volumeMonthly /(1024*1024*1024)).toFixed(2)}
                              {' GB'}
                            </TableCell>
                            <TableCell>
                              {(agent.volumeDaily /(1024*1024*1024)).toFixed(2)}
                              {' GB'}
                            </TableCell>
                            <TableCell>
                              {agent.downloadsMonthly}
                            </TableCell>
                            <TableCell>
                              {agent.downloadsDaily}
                            </TableCell>
                            <TableCell>
                              {icon}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Agents;

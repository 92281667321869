import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export const AlertDialog = React.memo(({
  isDisabled, isoData, update, isoID, setUpdate, status, release, ...rest
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
    console.log(isoID);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleRelease = () => {
    release(status, isoID, isoData) 
    handleClose()  
  }

 if(isDisabled) {
   return <></>
 } else {
   return (
    <>
    {/* {status === 1 ? <Button variant="contained" color="primary" style={{float: 'right',}}  disabled={isDisabled} size="small" onClick={handleClickOpen}>Freigeben</Button>
                  : status === 2 ? <Button variant="outlined" style={{ float: 'right' }} color="primary" disabled={isDisabled} size="small" onClick={handleClickOpen}>Deaktivieren</Button>
                  : <Button variant="outlined" style={{float: 'right', }} size="small" disabled={true} >Freigeben</Button>
    } */}

    {status === 2 ? <Button variant="outlined" style={{ float: 'right' }} color="primary" disabled={isDisabled} size="small" onClick={handleClickOpen}>Deaktivieren</Button>
                  : status === 0 ? <Button variant="outlined" style={{float: 'right', }} size="small" disabled={true} >Freigeben</Button> 
                  :  <Button variant="contained" color="primary" style={{float: 'right',}}  disabled={isDisabled} size="small" onClick={handleClickOpen}>Freigeben</Button>
    }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...rest}
      >
        <DialogTitle id="alert-dialog-title">
          Bitte bestätigen
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie die Änderungen für Image #{isoData} speichern?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleRelease} variant="contained" size="small" color="primary" autoFocus>
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
 }
})

AlertDialog.propTypes = {
  isDisabled: PropTypes.any,
  isoID: PropTypes.any,
  updateRelease: PropTypes.any,
  status: PropTypes.any
};

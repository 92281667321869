import CachedIcon from '@material-ui/icons/Cached';
import ErrorIcon from '@material-ui/icons/Error';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const DownloadStatus = ({ status }) => {
  let component;
  switch (status) {
    case 0:
      component = <CachedIcon fontSize="large" style = {{color:"#24bced"}} />;
      break;
    case 1:
      component = <ErrorIcon fontSize="large" style={{ color: 'red' }} />;
      break;
    case 2:
      component = <DoneAllIcon fontSize="large" style={{ color: '	#004444' }} />;
      break;
    default:
      component = <HelpOutlineIcon fontSize="large" style={{ color: 'orange' }} />;
      break;
  }

  return (
    component
  );
};

export default DownloadStatus;
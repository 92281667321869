import {
  GET_TOKEN_BEGIN,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  GET_TOKEN_LOGIN,
  GET_TOKEN_LOGOUT
} from '../actions/getTokenActions';

const initialState = {
  apitoken: null,
  islogin: false,
  loading: false,
  error: null
};

export default function getTokenReducer(state = initialState, action) {

  // console.log("------- Token Reducer -----------");
  // console.log(action.type);
  // console.log(action.payload);
  // console.log("------- Token Reducer End-----------");
  switch (action.type) {
    case GET_TOKEN_LOGIN:
      return {
        ...state,
        islogin: true,
        error: null
      };

    case GET_TOKEN_LOGOUT:
      return {
        ...state,
        apitoken: null,
        islogin: false,
        error: null
      };
    case GET_TOKEN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GET_TOKEN_SUCCESS:
      return {
        ...state,
        apitoken: action.payload.tokendata,
        loading: false,
        islogin: true,
        error: null
      };

    case GET_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
}

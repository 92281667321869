import { useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDataTC } from 'src/reducers/auth-reducer';
import React from 'react';

const AccountProfileDetails = React.memo((props) => {
  const [values, setValues] = useState({...props})
  const dispatch = useDispatch();
  const token = useSelector(state => state.gettocken.apitoken);

  const handleChange = useCallback((event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }, []);
  const updateUserData = useCallback(() => {
    dispatch(updateUserDataTC(token, values.firstName, values.lastName, values.email, values.jobTitle, values.phone))
   }, [dispatch])

  return (
    <form
      autoComplete="off"
      noValidate
      //{...rest}
    >
      <Card>
        <CardHeader
          //subheader="The information can be edited"
          title="Profil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="Vorname"
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nachname"
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                //onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefonnummer"
                disabled={true}
                name="phone"
                //onChange={}
                value=''
                variant="outlined"
              />
            </Grid>
            {/* <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value=''
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={handleChange}
                required
                value=''
                variant="outlined"
              />
            </Grid> */}
            <Grid
            item
            md={12}
            xs={12}
            >
              <TextField
                fullWidth
                label="Position"
                name="jobTitle"
                onChange={handleChange}
                required
                value={values.jobTitle}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updateUserData}
          >
            Speichern
          </Button>
        </Box>
      </Card>
    </form>
  );
});

AccountProfileDetails.propTypes = {
  firstNameProps: PropTypes.any,
  lastNameProps: PropTypes.any,
  jobTitleProps: PropTypes.any,
  emailProps: PropTypes.any,
};
export default AccountProfileDetails;

import { Helmet } from 'react-helmet';
import CustomerToolbar from 'src/components/customer/CustomerToolbar';
import { useLocation, useNavigate } from 'react-router';
import { useCallback, useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import Sorting from '../Sorting';
import DownloadStatus from './dounloadStatus';
import { CustomSkeleton } from '../CustomSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import { checkAutoLogin } from 'src/actions/getTokenActions';

const Customer = () => {
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [downloads, setDownloads] = useState([]);
  const [customer, setCustomer] = useState({firstName:'', lastName:''});
  const apiurl = sessionStorage.getItem('apiurl')
  const isLogin = useSelector(state => state.gettocken.islogin);
  const navigate = useNavigate();
  const [sortMode, setSortMode] = useState('');
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('');
  //const token = useSelector(state => state.gettocken.apitoken);
  const [sortID, setIDSorting] = useState('none');
  const [agent, setAgentSorting] = useState('none');
  const [start, setStartSorting] = useState('none');
  const [end, setEndSorting] = useState('none');
  const [dauer, setDauerSorting] = useState('none');
  const [isScrolling, setScrolling] = useState(false)
  const myRef = useRef(null)
  const dispatch = useDispatch()

  const onScroll = (arr) => {
    setDownloads([...downloads, ...arr]);
  }
  const onSortOrSearch = (arr) => {
    myRef.current.scrollIntoView()
    setDownloads(arr)
  }

  useEffect(() => {
    let token = checkAutoLogin(dispatch)
    if(!token && isLogin === false) {
      navigate('/login', { replace: true });
    } else {
      axios.get(`https://${apiurl}/api/DatDownload/list/byUser/${location.state.id}?PageNumber=${page + 1}&pageSize=${limit}&SortMode=${sortMode}&Sort=${sort}&Query=${query}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      }).then(res => {
        if (isScrolling) {
          onScroll(res.data.data)
        } else {
          onSortOrSearch(res.data.data)
        }
        setCount(res.data.totalRecords);
        setScrolling(false)
        setCustomer(location.state)
      })
    }
  }, [page, limit, sortMode, sort, query, isLogin])

  const syncSorting = (func) => {
    const funcArray = [setIDSorting, setDauerSorting, setAgentSorting, setStartSorting, setEndSorting];
    const sortArray = funcArray.filter((el) => el !== func);
    return sortArray.forEach((el) => el('none'));
  };
  const handleIDSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setIDSorting(sortFactor);
    syncSorting(setIDSorting);
    setPage(0)
    setSort('imageId')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const handleAgentSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setAgentSorting(sortFactor);
    syncSorting(setAgentSorting);
    setPage(0)
    setSort('agentName')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const handleStartSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setStartSorting(sortFactor);
    syncSorting(setStartSorting);
    setPage(0)
    setSort('downloadStarted')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const handleEndSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setEndSorting(sortFactor);
    syncSorting(setEndSorting);
    setPage(0)
    setSort('downloadCompleted')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const handleDauerSorting = useCallback((sortFactor) => {
    setScrolling(false)
    setDauerSorting(sortFactor);
    syncSorting(setDauerSorting);
    setPage(0)
    setSort('duration')
    if(sortFactor === 'AZ'){
      setSortMode('asc')
    } else if(sortFactor === 'ZA') {
      setSortMode('desc')
    } else {
      setSortMode('')
    }
  }, []);

  const searchISO = useCallback((isoName) => {
    setScrolling(false)
    setPage(0)
    setQuery(isoName)
  }, [])

  const fetchData = () => {
    setScrolling(true)
    setPage(page + 1)
  }

  return (
    <>
      <Helmet>
        <title>
          {customer.firstName}
          {customer.lastName}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <CustomerToolbar searchItem={searchISO} customer={location.state} />
          <Box sx={{ pt: 3 }}>
            <Card>
              <PerfectScrollbar>
                <Box sx={{ minWidth: 1020 }}>
                <InfiniteScroll
                  dataLength={downloads.length} //This is important field to render the next data
                  next={fetchData}
                  height={'72vh'}
                  hasMore={downloads.length < count}
                  loader={<CustomSkeleton />}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b></b>
                    </p>} >
                  <Table stickyHeader>
                    <TableHead ref={myRef}>
                      <TableRow>
                        <TableCell>
                          ISO ID
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={sortID} callback={handleIDSorting} /></span>
                        </TableCell>
                        <TableCell>
                          AGENT
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={agent} callback={handleAgentSorting} /></span>
                        </TableCell>
                        <TableCell>
                          Download START
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={start} callback={handleStartSorting} /></span>
                        </TableCell>
                        <TableCell>
                          Download END
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={end} callback={handleEndSorting} /></span>
                        </TableCell>
                        <TableCell>
                          Dauer
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}><Sorting sortFactor={dauer} callback={handleDauerSorting} /></span>
                        </TableCell>
                        <TableCell>
                          Status
                          
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {downloads.map((download) => (
                        <TableRow
                          hover
                          key={download.id}
                        >
                          <TableCell>
                            {download.imageId}
                          </TableCell>
                          <TableCell>
                            {download.agentName}
                          </TableCell>
                          <TableCell>
                          {moment(download.downloadStarted).locale('de').format('DD.MM.YYYY, HH:mm Z')}
                          </TableCell>
                          <TableCell>
                          {moment(download.downloadCompleted).locale('de').format('DD.MM.YYYY, HH:mm Z')}
                          </TableCell>
                          <TableCell>
                            {download.duration}
                          </TableCell>
                          <TableCell>
                            <DownloadStatus status = {download.status} />
                        </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  </InfiniteScroll>
                </Box>
              </PerfectScrollbar>
              {/* <TablePagination
                component="div"
                //count={customer.isos.length}
                count={count}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
              /> */}
              <div style={{
                display: 'flex', alignItems: 'center', minHeight: '52px', justifyContent: 'flex-end', padding: '5px', fontFamily: "Roboto", fontWeight: '400',
                fontSize: '0.875rem', lineHeight: 1.43, letterSpacing: '0.01071em', marginRight: '10px'
              }}>
                {'1 - '}
                {downloads.length}
                {' von '}
                {count}
              </div>
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Customer;

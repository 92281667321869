import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {getToken} from './../actions/getTokenActions';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { useEffect } from 'react';
import { getAuthUserDataThunk } from 'src/reducers/auth-reducer';

const Login = () => {
  const navigate = useNavigate();
  const isLogin = useSelector(state => state.gettocken.islogin);
  const token = useSelector(state => state.gettocken.apitoken);
  const loginErr = useSelector(state => state.gettocken.error);
  const dispatch = useDispatch();

  console.log('LoginErr', loginErr)

  useEffect(()=> {
    isLogin && navigate('/app/dashboard', { replace: true });
    isLogin && dispatch(getAuthUserDataThunk(token))
  }, [isLogin, token])

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required') ,
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              dispatch(getToken({username: values.email, password: values.password}));
          
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Anmelden
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Melden Sie sich auf der internen Plattform an 
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email) || loginErr !== null}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                 // type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password) || loginErr !== null}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                   // disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Anmelden
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;

import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import Storage from '@material-ui/icons/Storage';
import PropTypes from 'prop-types';

const TotalDataVolume = ({ totalDataVolume, ...rest }) => (
  <Card {...rest}>
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="#ffd504"
            gutterBottom
            variant="h6"
          >
            GESAMTDATENVOLUMEN
          </Typography>
          <Typography
            color="white"
            variant="h3"
          >
            {`${totalDataVolume} GB`}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: '#8D9AB3',
              color: 'black',
              height: 56,
              width: 56
            }}
          >
            <Storage />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

TotalDataVolume.propTypes = {
  totalDataVolume: PropTypes.any
};

export default TotalDataVolume;

import axios from "axios";

const initialState = {
  id: null,
  rowVersion: '',
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  description: '',
  isAdminRole: false,
  roles: [],
  isActive: false,
  password: '',
  accountId: null,
  country: '',
  lastIP: '',
  account: {},
  isLogin: false,
};

export const setAuthData = (data) => ({ type: 'SET_USER_DATA', data: data })

export const getAuthUserDataThunk = (token) => {
  const apiurl = sessionStorage.getItem('apiurl')
  return (dispatch) => {
    axios.get(`https://${apiurl}/api/User/me`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    }).then(res => {
      dispatch(setAuthData(res.data))
    })
  }
}

export const updateUserData = (firstname, lastname, mail, position, phone) => ({
  type: UPDATE_USER_DATA, firstname, lastname, mail, position, phone
});

export const updateUserPasswort = (password) => ({
  type: 'UPDATE_USER_PASSWORT', password
});

export function updateUserDataTC(token, firstname, lastname, email, description, phone) {
  const apiurl = sessionStorage.getItem('apiurl')
  return (dispatch, getState) => {
    const id= getState().authReducer.id
    const body= {id, firstname, lastname, email, description }
    console.log('body !!!!!!', body)
    axios.put(`https://${apiurl}/api/User/me`, body, {headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      }}).then(res => {
     dispatch(getAuthUserDataThunk(token))
       })
  };
}

export function updateUserPasswordTC(token, password) {
  const apiurl = sessionStorage.getItem('apiurl')
  return (dispatch, getState) => {
    const id= getState().authReducer.id
    const body= {id, password }
    console.log('body PASSWORD', body)
    axios.put(`https://${apiurl}/api/User/me/setPassword`, body, {headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      }}).then(res => {
       dispatch(updateUserPasswort(password))
       })
  };
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        ...action.data,
        isLogin: true,
      };
    case 'UPDATE_USER_PASSWORT':
      return {
        ...state,
        password: action.password
      }
    default:
      return state;
  }
}

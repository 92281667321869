import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomersList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import IsosList from 'src/pages/IsosList';
import Register from 'src/pages/Register';
import Customer from './components/customer/Customer';
import Agents from './pages/AgentsList';
import Agent from './components/agents/Agent';
import UsersList from './pages/Users';
import UserData from './components/users/UserData';
import NewUser from './components/users/NewUser';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <IsosList /> },
      { path: 'agents', element: <Agents /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'test/:testid', element: <Customer /> },
      { path: 'agent/:agent', element: <Agent /> }, // hat keine Logout Schutz
      { path: 'users', element: <UsersList /> },
      { path: 'users/user/:testid', element: <UserData /> },
      { path: 'users/newuser/', element: <NewUser /> },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
